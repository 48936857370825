var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Pages', {
    attrs: {
      "title": "Utility Consumption"
    }
  }, [_c('PageLandscape', {
    attrs: {
      "no-header": "",
      "no-footer-text": ""
    }
  }, [_vm.asset && !_vm.loading ? _c('div', {
    staticClass: "etn-report"
  }, [_vm.analytics.elecConsumption.assetId ? _c('div', [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v("Electricity Consumption")]), _c('AccountMonthlyConsumption', {
    staticStyle: {
      "height": "700px"
    },
    attrs: {
      "period": _vm.period,
      "data": _vm.analytics.elecConsumption.data,
      "units": "kWh"
    }
  })], 1) : _vm._e()]) : !_vm.asset && !_vm.loading ? _c('div', [_vm._v("Asset not found")]) : _vm.loading ? _c('div', {
    staticClass: "col-md-3 pr-4"
  }, [_vm._v("Loading...")]) : _vm._e()]), _c('PageLandscape', {
    attrs: {
      "no-header": "",
      "no-footer-text": ""
    }
  }, [_vm.asset && !_vm.loading ? _c('div', {
    staticClass: "etn-report"
  }, [_vm.analytics.gasConsumption.assetId ? _c('div', [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v("Gas Consumption")]), _c('AccountMonthlyConsumption', {
    staticStyle: {
      "height": "700px"
    },
    attrs: {
      "period": _vm.period,
      "data": _vm.analytics.gasConsumption.data,
      "units": "kWh"
    }
  })], 1) : _vm._e()]) : !_vm.asset && !_vm.loading ? _c('div', [_vm._v("Asset not found")]) : _vm.loading ? _c('div', {
    staticClass: "col-md-3 pr-4"
  }, [_vm._v("Loading...")]) : _vm._e()]), _c('PageLandscape', {
    attrs: {
      "no-header": "",
      "no-footer-text": ""
    }
  }, [_vm.asset && !_vm.loading ? _c('div', {
    staticClass: "etn-report"
  }, [_vm.analytics.waterConsumption.assetId ? _c('div', [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v("Water Consumption")]), _c('AccountMonthlyConsumption', {
    staticStyle: {
      "height": "700px"
    },
    attrs: {
      "period": _vm.period,
      "data": _vm.analytics.waterConsumption.data,
      "units": "kWh"
    }
  })], 1) : _vm._e()]) : !_vm.asset && !_vm.loading ? _c('div', [_vm._v("Asset not found")]) : _vm.loading ? _c('div', {
    staticClass: "col-md-3 pr-4"
  }, [_vm._v("Loading...")]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }