<template>
  <Pages title="Utility Consumption">
    <PageLandscape no-header no-footer-text>
      <div v-if="asset && !loading" class="etn-report">
        <!-- Monthly Charges START -->
        <div v-if="analytics.elecConsumption.assetId">
          <h5 class="text-center">Electricity Consumption</h5>
          <AccountMonthlyConsumption style="height: 700px" :period="period" :data="analytics.elecConsumption.data" units="kWh" />
        </div>
        <!-- Monthly Charges END -->
      </div>
      <div v-else-if="!asset && !loading">Asset not found</div>

      <div v-else-if="loading" class="col-md-3 pr-4">Loading...</div>
    </PageLandscape>
    <PageLandscape no-header no-footer-text>
      <div v-if="asset && !loading" class="etn-report">
        <!-- Monthly Charges START -->
        <div v-if="analytics.gasConsumption.assetId">
          <h5 class="text-center">Gas Consumption</h5>
          <AccountMonthlyConsumption style="height: 700px" :period="period" :data="analytics.gasConsumption.data" units="kWh" />
        </div>
        <!-- Monthly Charges END -->
      </div>
      <div v-else-if="!asset && !loading">Asset not found</div>

      <div v-else-if="loading" class="col-md-3 pr-4">Loading...</div>
    </PageLandscape>
    <PageLandscape no-header no-footer-text>
      <div v-if="asset && !loading" class="etn-report">
        <!-- Monthly Charges START -->
        <div v-if="analytics.waterConsumption.assetId">
          <h5 class="text-center">Water Consumption</h5>
          <AccountMonthlyConsumption style="height: 700px" :period="period" :data="analytics.waterConsumption.data" units="kWh" />
        </div>
        <!-- Monthly Charges END -->
      </div>
      <div v-else-if="!asset && !loading">Asset not found</div>

      <div v-else-if="loading" class="col-md-3 pr-4">Loading...</div>
    </PageLandscape>
  </Pages>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import PageLandscape from './components/PageLandscape';
import Pages from './components/Pages';

import AccountMonthlyConsumption from './charts/AccountMonthlyConsumption';

export default {
  name: 'UtilityConsumptionPDF',
  components: {
    AccountMonthlyConsumption,
    PageLandscape,
    Pages
  },
  data() {
    return {
      error: '',
      loading: true,
      period: {
        startDate: moment('2023-05', 'YYYY-MM').startOf('month'),
        endDate: moment('2024-04', 'YYYY-MM').endOf('month'),
        historicalStartDate: null,
        comparisonPeriods: 3
      },
      ultimateParentEntity: null
    };
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      company: 'company/company',
      report: 'report/report',
      analytics: 'analytics/analytics',
      units: 'util/units'
    })
  },
  async mounted() {
    const { assetId } = this.$route.query;

    if (!assetId) this.errorRedirect('Missing Asset ID in URL');

    this.listUnits();

    await this.getAsset({ id: assetId });
    this.ultimateParentEntity = await this.getUltimateParentEntity({ id: this.asset.entityId });

    await this.getCompany({ id: this.asset.companyId });

    this.period.historicalStartDate = moment(this.period.startDate).subtract(this.period.comparisonPeriods, 'years');

    const params = {
      startDate: this.period.historicalStartDate.format('YYYY-MM-DD'),
      endDate: this.period.endDate.format('YYYY-MM-DD'),
      granularity: 'monthly'
    };

    await Promise.all([
      this.getAnalyticsV2({
        id: 'elecConsumption',
        params: {
          id: 'consumption',
          ...params,
          dataType: 'asset',
          accountType: 'electricity',
          assetId: this.asset._id,
          companyId: this.asset.companyId
        }
      }),
      this.getAnalyticsV2({
        id: 'gasConsumption',
        params: {
          id: 'consumption',
          ...params,
          dataType: 'asset',
          accountType: 'gas',
          assetId: this.asset._id,
          companyId: this.asset.companyId
        }
      }),
      this.getAnalyticsV2({
        id: 'waterConsumption',
        params: {
          id: 'consumption',
          ...params,
          dataType: 'asset',
          accountType: 'water',
          assetId: this.asset._id,
          companyId: this.asset.companyId
        }
      })
    ]);

    this.loading = false;
  },
  methods: {
    ...mapActions({
      getAsset: 'asset/get',
      getCompany: 'company/get',
      getReport: 'report/get',
      getUltimateParentEntity: 'entity/ultimateParent',
      listUnits: 'util/listUnits',
      getAnalyticsV2: 'analytics/getAnalyticsV2'
    }),
    errorRedirect(reason) {
      this.$router.push({ name: 'machine-error', query: { errorCode: 500, errorData: { message: reason } } });
    }
  }
};
</script>
<style lang="scss">
.etn-report {
  font-size: 16px;

  table th,
  table td {
    padding: 0.3rem 0.5rem;
    height: 20px;
  }

  .logo {
    object-fit: contain;
    object-position: right center;
    height: 65px;
    width: 100%;
  }

  .divider-bottom {
    border-bottom: 3px solid #f4f4f4;
  }

  .divider-right {
    border-right: 3px solid #f4f4f4;
  }
}
</style>
